/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import 'ngx-sharebuttons/themes/default';

.bordered-row {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background: #eeeeee;
  min-height: 200px;
}
// blog content overrides
.wp-block-image {
  img {
    max-width: 100% !important;
  }
}

// Font Style Overrides
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
label,
input {
  font-family: "Open Sans", sans-serif;
}

.fs-7 {
  font-size: 13px !important;
}

a.fs-7 {
  text-decoration: none;
  color: #5c2530;
  font-weight: 700;
}

.link {
  font-size: 18px;
  font-weight: 600;
  a {
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
  }
}

a,
.cursor-pointer {
  cursor: pointer;
}

.text-ihm {
  color: #5c2530;
}

.text-light-grey {
  color: #999999;
}

.btn-primary.btn-sm, .btn-outline-primary.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
// btn ovverides
.btn-primary {
  background-color: #5c2530;
  border: #86535e;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  min-height: 38px;
}
.btn-primary:hover,
.btn-primary:active {
  background-color: #86535e;
  border: #5c2530;
}
.btn-primary:focus {
  color: #fff;
  background-color: #86535e;
  border: #5c2530;
  box-shadow: 0 0 0 0.25rem #5c25305d;
}
.btn-primary.disabled {
  color: white;
  background-color: grey;
  border-color: grey;
}
.btn-primary:disabled {
  background-color: #5c2530;
  opacity: 0.5;
}

.btn-outline-primary {
  background-color: transparent;
  border: 1px solid #5c2530;
  color: #5c2530;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  min-height: 38px;
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: white;
  border: #86535e;
  background-color: #86535e;
}
.btn-outline-primary:focus {
  color: #5c2530;
  background-color: transparent;
  border: #5c2530;
  box-shadow: 0 0 0 0.25rem #5c25305d;
}
// bg colors
.bg-grey {
  background: #eeeeee;
  border: 1px solid #cecece;
}
// common form styling
// styled-form
.styled-form {
  border: none;
  h2 {
    font-size: 18px;
    font-weight: 700;
  }
  label {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #666666;
  }
  input,
  textarea,
  select {
    background-color: #eeeeee;
    border: 1px solid #cebdc1;
  }
  .form-control.is-invalid {
    border-color: #dc3545;
  }
}
// bordered-form
.bordered-form {
  max-width: 700px;
  h1 {
    font-size: 36px;
    font-weight: 700;
  }
  .form {
    border: none;
    h2 {
      font-size: 18px;
      font-weight: 700;
    }
    label {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: #666666;
    }
    input,
    textarea,
    select {
      background-color: #eeeeee;
      border: 1px solid #cebdc1;
    }
    .form-control.is-invalid {
      border-color: #dc3545;
    }
  }
}

// Breadcrumb styling
.breadcrumb {
  font-weight: 600;
  .breadcrumb-item {
    font-size: 12px;

    a {
      color: #5c2530;
      text-decoration: none;
    }
  }
  .breadcrumb-item.active {
    color: #666666;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">")
      /* rtl: var(--bs-breadcrumb-divider, "/") */;
  }
}

@media (min-width: 768px) {
  .bordered-row {
    margin-right: 15px !important;
    min-height: 300px;
  }
  // breadcrumbs
  .breadcrumb {
    .breadcrumb-item {
      font-size: 14px;
    }
  }
  // btn overrides
  .btn-primary.btn-sm, .btn-outline-primary.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
  .btn-primary,
  .btn-outline-primary {
    font-size: 18px;
    font-weight: 700;
  }
  .bordered-form {
    max-width: 700px;
    .form {
      border: 1px solid #ffffff;
      border-radius: 5px;
    }
  }
}

// CUSTOM MODAL STYLES
body.zy-modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;

  .app-container {
    filter: blur(5px);
  }
}

.zy-modal {
  h2 {
    font-size: 24px !important;
  }
}

// sizing the width of the modal classes
.form {
  .zy-modal {
    max-width: 500px !important;
    margin: 0px auto;
  }
}

.small {
  .zy-modal {
    max-width: 400px !important;
    margin: 0px auto;
  }
}

.medium {
  .zy-modal {
    max-width: 600px !important;
    margin: 0px auto;
  }
}

.large {
  .zy-modal-body {
    min-width: 600px !important;
    max-width: 800px !important;
    width: auto;
    margin: 0px auto;
  }
}

.xlarge {
  .zy-modal {
    max-height: 85vh !important;
    overflow-y: scroll;
  }
  .zy-modal-body {
    max-height: 85vh !important;
    overflow-y: scroll;
    min-width: 85vw !important;
    max-width: 85vw !important;
    width: auto;
    margin: 0px auto;
  }
}

.map {
  .zy-modal {
    max-width: 1200px !important;
    width: 80%;
    margin: 0px auto;
    max-height: 85vh;
    overflow-y: scroll;
  }
}

.xl {
  .zy-modal {
    max-width: 95% !important;
    width: 95%;
    margin: 0px auto;
    min-height: 45vh;
    max-height: 85vh !important;
    overflow-y: scroll;
  }
  .zy-modal-body {
    min-height: 45vh;
    max-height: 85vh !important;
    max-width: 95% !important;
    overflow-y: scroll;
    width: auto;
    margin: 0px auto;
  }
}

@media (max-width: 540px) {
  .form,
  .small,
  .medium,
  .large {
    .zy-modal {
      max-width: 100vw !important;
      width: 98%;
      margin: 0px auto;
      overflow-y: scroll;
      max-height: 95vh;
    }
    // Patrick added this above and this below to get the modal to scroll correctly
    .zy-modal-body {
      min-width: 98% !important;
      max-width: 100vw !important;
      width: auto;
      margin: 0px auto;
      overflow-y: scroll;
      max-height: 95vh;
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
